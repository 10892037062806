<template>
  <div class="index">
    <div class="resources-content">
      <div class="management-admin-header">
        <el-button type="primary" @click="addClassify()">创建分类</el-button>
      </div>
      <div class="scro">
      <el-scrollbar>
          <el-table
          :data="courseTable"
          style="width: 100%; flex: 1"
          size="medium"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          :row-key="getRowKey"
          class="customTable"
          :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#eee',fontSize: '16px',height:'60px'}"
          :cell-style="{ fontSize: '12px', color: '#333', height: '70px' }"
        >
          <el-table-column prop="category_name" label="名称"></el-table-column>
          <el-table-column prop="time" label="创建时间"></el-table-column>
          <el-table-column label="操作" align="center" width="240px">
            <template slot-scope="scope">
              <div class="link-list">
                <el-link type="primary" v-if="scope.row.pid===0" :underline="false" @click="addClassify(scope.row)">添加二级分类 </el-link>
                <el-link type="primary" v-else :underline="false">&#8195;&#8195;&#8195;&#8195;&#8195;&#8195;</el-link>
                <el-link
                  type="primary"
                  :underline="false"
                  @click="editClassify(scope.row)"
                  >编辑</el-link
                >
                <el-link
                  type="danger"
                  :underline="false"
                  @click="deleteClassifyRow(scope.row)"
                  >删除</el-link
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
   
      </div>
      <el-pagination
        class="pages-center"
        :current-page="coursePages.currentPageNum"
        :page-size="coursePages.eachPageNum"
        layout="prev, pager, next, jumper"
        :total="coursePages.total"
        @current-change="courseCurrentChange"
      >
      </el-pagination>
      <el-dialog
        :visible.sync="dialogAddClassify"
        custom-class="green-dialog"
        width="500px"
        @close="resetForm()"
        :close-on-click-modal="false"
      >
        <template slot="title">
            <div style="font-weight: 600;"><span style="display: inline-block;width: 4px;height: 12px;margin-right: 10px;background-color: #2821fc;"></span>{{this.classifyTitleType}} </div>
        </template>
        <el-form
          :model="addClassifyForm"
          ref="addClassifyForm"
          :rules="resourcesRules"
          label-width="100px"
          class="demo-form-inline"
 
        > 
          <el-form-item label="分类名称" prop="name">
            <el-input v-model="addClassifyForm.name" autocomplete="off" placeholder="2-50字"></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button size="medium" @click="dialogAddClassify = false"
            >取 消</el-button
          >
          <el-button
            size="medium"
            type="primary"
            @click="addToForm('addClassifyForm')"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {commodityList,addcommodity,delcommodity} from '@/utils/apis'
export default {
  name: "management",
  data() {
    return {
      dialogAddClassify: false,
      classifyTitleType: "",
      addClassifyForm: {
        name: "",
        id: "",
      },
        formLabelWidth:'80px',
      resourcesRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
      //表格数据
      courseTable: [
        // {
        //   id: 1,
        //   time: "2016-05-02",
        //   name: "王小虎",
        //   address: "上海市普陀区金沙江路 1518 弄",
        // },
        // {
        //   id: 2,
        //   time: "2016-05-01",
        //   name: "王小虎",
        //   address: "上海市普陀区金沙江路 1519 弄",
        //   children: [
        //     {
        //       id: 31,
        //       date: "2016-05-01",
        //       name: "王小虎11",
        //       address: "上海市普陀区金沙江路 1519 弄",
        //     },
        //     {
        //       id: 32,
        //       date: "2016-05-01",
        //       name: "王小虎22",
        //       address: "上海市普陀区金沙江路 1519 弄",
        //     },
        //   ],
        // },
      ],
      pid:0,
      distinguishBtn:'',
      category_id:'',
      //分页
      coursePages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
    };
  },

  mounted(){
    this.getCommodityList()
  },

  methods: {
        //获取列表id
        getRowKey(row) {
                return row.category_id;
        },
       //获取列表
        getCommodityList(){
           let param = {
              paging: 1,
              page:this.coursePages.currentPageNum,
              pageSize:this.coursePages.eachPageNum
            }
            commodityList(param).then((res) => {  
                this.courseTable = res.data.list
                this.coursePages.total = res.data.total;
            }).catch((err) => {
                console.error('err', err);
            });
        },
        //创建分类缺点
        addToForm(formName){
              this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let param = {
                            category_name: this.addClassifyForm.name,
                            pid:this.pid,
                            category_id:this.category_id
                        }
                        if (this.distinguishBtn === 'edit') {
                            // 编辑
                            param.pid =  this.pid
                        }
                        
                        addcommodity(param).then((res) => {
                            this.$message.success(res.msg);
                            this.dialogAddClassify = false;
                            this.getCommodityList()
                            this.resetForm()
                        }).catch((err) => {
                            console.error('err', err)
                        })
                    }
                });
        },
      //重置表单
     resetForm() {
        this.$refs.addClassifyForm.resetFields();
        this.addClassifyForm = this.$options.data().addClassifyForm;
      },

      //编辑
      editClassify(row){
         this.addClassifyForm.name = row.category_name;
         this.pid = row.category_id;
         this.category_id = row.category_id
         if(row.pid){
                this.classifyTitleType = '编辑二级分类'
                this.pid = row.pid
            }else {
                this.classifyTitleType = '编辑分类'
          }
          this.dialogAddClassify = true;
          this.distinguishBtn = 'edit';
      },

    //创建分类
    addClassify(row) {
      if(row){
         this.pid = row.category_id;
         this.classifyTitleType = '添加二级分类'
          this.category_id = ''
      }else{
          this.pid = 0
          this.classifyTitleType = '创建分类'
           this.category_id = ''
      }
      this.dialogAddClassify = true;
      this.distinguishBtn = 'add';
    },

    //删除列表
    deleteClassifyRow(row){
     this.$confirm(`是否删除该分类，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let param = {
                      category_id:row.category_id	
                    }
                    delcommodity(param).then((res) => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            duration:1000,
                            onClose: () => {
                                this.getCommodityList()
                            }
                        })
                    }).catch((err) => {
                        console.error('err', err)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
    },

    // 切换分页
    courseCurrentChange(val) {
      this.coursePages.currentPageNum = val;
       this.getCommodityList()
    },
  },
};
</script>



<style  lang="scss" scoped>
.index {
  box-sizing: border-box;
  height: 100%;
  padding: 20px 20px 0;
}
.resources-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .management-admin-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    height: 50px;
  }
}
.scro{
  height: 80%;
  .el-scrollbar {
          height: 100%;
  }

}
.customTable th.el-table__cell {
  background-color: #f0f3fc !important;
}
.dialog-footer {
  text-align: center;
  margin-top: 20px;
}
.pages-center {
  margin: 20px 0 20px;
  text-align: center;
}
.demo-ruleForm .el-form-item__content{
  display:inline-block;
}
.demo-form-inline{
      display: flex;
    flex-direction: column;
    padding: 0 30px;
}
.el-dialog__title {
      border-left: 1px solid #000 !important;
}

</style>